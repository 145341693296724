import { template as template_22e323e8721b4a74af1fceb7de0efe84 } from "@ember/template-compiler";
const SidebarSectionMessage = template_22e323e8721b4a74af1fceb7de0efe84(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
