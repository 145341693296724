import { template as template_75ac2b66d2fe4edd81236f39775e7b43 } from "@ember/template-compiler";
const FKLabel = template_75ac2b66d2fe4edd81236f39775e7b43(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
